<template>
  <div>
    <div :class="[$style.card]">
      <div :class="$style.title">
        Оповещать при обнаружении нестандартного поведения по категориям данных:
      </div>
      <label
        v-for="sett in settings.slice(0, -2)"
        :key="sett.stype"
        :class="$style.settContainer"
        @click="check(sett.stype)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ sett.description }}
        </div>
        <toggle-button
          v-model="sett.checked"
          :value="sett.checked"
          :sync="true"
          @click.native.prevent
        />
      </label>
    </div>
    <div :class="[$style.card]">
      <div :class="$style.settContainer">
        <label :class="$style.label"> Временной интервал для анализа </label>
        <div :class="$style.inline">
          <input
            v-model.number="statPeriod"
            type="number"
            :class="$style.input"
            @change="change()"
          >
          <div :class="$style.measure">
            рабочих дней
          </div>
        </div>
      </div>
      <label
        v-for="sett in settings.slice(-2)"
        :key="sett.stype"
        :class="$style.settContainer"
        @click="check(sett.stype)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ sett.description }}
        </div>
        <toggle-button
          v-model="sett.checked"
          :value="sett.checked"
          :sync="true"
          @click.native.prevent
        />
      </label>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getAcselParams } from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    settings: [
      {
        description: 'Печать документов на принтер',
        stype: 31,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Посещение веб-сайтов',
        stype: 32,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Выгрузка файлов в интернет',
        stype: 33,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Копирование файлов на съемные накопители',
        stype: 34,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Общение в мессенджерах (количество сообщений)',
        stype: 35,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Переписка в Skype (количество сообщений)',
        stype: 36,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Общение в социальных сетях (количество сообщений)',
        stype: 37,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Переписка по почте (количество писем)',
        stype: 38,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Переписка по web почте (через браузер)',
        stype: 39,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Поисковые запросы',
        stype: 40,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Запуски программ',
        stype: 41,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Сравнивать относительно самого сотрудника',
        stype: 42,
        checked: false,
        val: 0,
        sval: '',
      },
      {
        description: 'Сравнивать относительно группы',
        stype: 43,
        checked: false,
        val: 0,
        sval: '',
      },
    ],
    result: {},
    statPeriod: 10,
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    check(stype) {
      const indexOfObject = this.settings.findIndex(
        (object) => object.stype === stype,
      );
      this.$set(
        this.settings[indexOfObject],
        'checked',
        !this.settings[indexOfObject].checked,
      );

      if (stype === 42 || stype === 43) {
        const compareSelf = this.settings.findIndex(
          (object) => object.stype === 42,
        );
        const compareOthers = this.settings.findIndex(
          (object) => object.stype === 43,
        );
        if (
          !this.settings[compareSelf].checked
          && !this.settings[compareOthers].checked
        ) {
          this.$set(this.settings[compareSelf], 'checked', true);
        }
      }

      this.$store.commit('settings/addAcsel', this.settings[indexOfObject]);
    },
    change() {
      this.$store.commit('settings/addAcsel', {
        stype: 30,
        val: this.statPeriod,
        sval: '',
        checked: true,
      });
    },
    async getSettings() {
      this.result = {};
      if (this.selectedComputer.id) {
        this.promise = await getAcselParams(
          this.selectedComputer.id,
          this.selectedComputer.type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
            const settings = this.settings.slice();
            settings.forEach((item) => {
              const found = this.result.find(
                (sett) => sett.stype === item.stype,
              );
              if (found) {
                item.checked = true;
                item.val = found.val;
                item.sval = found.sval;
              }
            });
            const days = this.result.find((sett) => sett.stype === 30);
            if (days) this.statPeriod = days.val;
            this.settings = settings;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}

.title {
  margin: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  border: 0px !important;
}

.measure {
  font-size: 14px;
}
</style>
